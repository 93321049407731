<template>
  <div  class="modal fade" id="preview3dModal" tabindex="-1">
    <div class="modal-dialog modal-xl">
        <div  class="modal-content text-center">
            <div v-if="showModal" class="modal-body modal-body-gallery">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
                <div class="row">
                  <div class="col-10">
                    <iframe v-if="threed" :src="threed.link" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="950" height="626" allowfullscreen></iframe>
                  </div>
                  <div class="col-2 ms-auto mt-auto mb-0">
                    <div v-if="qrCodeLink" class="text-left">
                      <strong>Scanne QR-Code mit deinen Smartphone um das Modell als virtuell(AR) zu betrachten:</strong>
                      <vue-qrcode :options="{ width: 160 }" :value="qrCodeLink"></vue-qrcode>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import VueQrcode from '@chenfengyuan/vue-qrcode'
export default {
  components: {
    VueQrcode
  },
  setup () {
    const store = useStore()
    const threed = computed(() => {
      return store.getters['configurator/threed']
    })
    const showModal = computed(() => {
      return store.getters['configurator/previewThreedModal']
    })
    const qrCodeLink = computed(() => {
      const threed = store.getters['configurator/threed']
      if (threed && threed.link) {
        const arr = threed.link.split('/')
        if (arr.length > 4) {
          return 'https://3dwarehouse.sketchup.com/ar-view/' + arr[4]
        }
      }
      return null
    })
    function closeModal () {
      store.commit('configurator/SET_PREVIEW_THREED_MODAL_SHOW', false)
    }
    return {
      showModal,
      closeModal,
      threed,
      qrCodeLink
    }
  }
}
</script>
